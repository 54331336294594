import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Truevined - Ashes & Wind",
  "featuredImage": "images/truevined-asheswind.jpg",
  "coverAlt": "Truevined - Ashes & Wind",
  "description": "Branding & Graphic Design for Truevined's debut album release",
  "date": "2018-05-10",
  "dateModified": "2018-05-10",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Graphic Design", "Music"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Album artwork for `}<strong parentName="p">{`Truevined`}</strong>{`’s new album - Ashes & Wind`}</p>
    <h2 {...{
      "id": "skills-used",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#skills-used",
        "aria-label": "skills used permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Skills Used`}</h2>
    <p>{`Graphic design, Photoshop, illustrator, etc.`}</p>
    <h2 {...{
      "id": "screenshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#screenshots",
        "aria-label": "screenshots permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Screenshots`}</h2>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1439px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/865c9898a995ba9424be4cd82d8786d7/31d6a/truevined-asheswind.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQFAgP/xAAXAQEBAQEAAAAAAAAAAAAAAAACAwAB/9oADAMBAAIQAxAAAAGLuj2m8tQQ0LibyB3f/8QAHBAAAgICAwAAAAAAAAAAAAAAAAIBAxAhERIx/9oACAEBAAEFAh4nEIOuuCnZYsdW9//EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAEDAQE/ASP/xAAVEQEBAAAAAAAAAAAAAAAAAAASIP/aAAgBAgEBPwFR/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEwcf/aAAgBAQAGPwIWQ//EABsQAAMAAgMAAAAAAAAAAAAAAAABESExEEFx/9oACAEBAAE/IZdMmtp6Y4g4Y3gylZOnER//2gAMAwEAAgADAAAAEEj3/P/EABcRAQEBAQAAAAAAAAAAAAAAABEAAVH/2gAIAQMBAT8Qx4s3Iv/EABgRAQEAAwAAAAAAAAAAAAAAAAABESFR/9oACAECAQE/ELsy6lf/xAAcEAEAAwACAwAAAAAAAAAAAAABABEhQVExYXH/2gAIAQEAAT8QyNn5ALQga8cSk4mtKeoNt0BmsAqj7qCpXuGQFT//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Truevined - Ashes & Wind",
              "title": "Truevined - Ashes & Wind",
              "src": "/static/865c9898a995ba9424be4cd82d8786d7/31d6a/truevined-asheswind.jpg",
              "srcSet": ["/static/865c9898a995ba9424be4cd82d8786d7/543cd/truevined-asheswind.jpg 360w", "/static/865c9898a995ba9424be4cd82d8786d7/20801/truevined-asheswind.jpg 720w", "/static/865c9898a995ba9424be4cd82d8786d7/31d6a/truevined-asheswind.jpg 1439w"],
              "sizes": "(max-width: 1439px) 100vw, 1439px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Truevined - Ashes & Wind`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      